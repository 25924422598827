<template>
  <div>
    <v-stepper v-model="$store.state.entities.stepper" non-linear>
      <v-stepper-header>
        <v-stepper-step :complete="$store.state.entities.stepper > 1" editable step="1">Datos del
          Proveedor</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="$store.state.entities.stepper > 2" :editable="$store.state.entities.stepper > 2"
          step="2">Teléfonos y Contactos</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="$store.state.entities.stepper > 3" :editable="$store.state.entities.stepper > 3"
          step="3">Convenios y Tarifas</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="$store.state.entities.stepper > 4" :editable="$store.state.entities.stepper > 4"
          step="4">Información Bancaria</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <!--DATOS DEL PROVEEDOR-->
        <v-stepper-content step="1">
          <SeccionProveedor />
        </v-stepper-content>

        <!--TELÉFONOS-->
        <v-stepper-content step="2">
          <SeccionTelefonos />
        </v-stepper-content>

        <!--CONVENIOS Y TARIFAS-->
        <v-stepper-content step="3">
          <SeccionConvenios />
        </v-stepper-content>

        <!--INFORMACIÓN BANCARIA-->
        <v-stepper-content step="4">
          <SeccionInformacionBancaria />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import Swal from "sweetalert2";
import SeccionProveedor from './seccionProveedor.vue';
import SeccionTelefonos from './seccionTelefonos.vue';
import SeccionConvenios from './seccionConvenios.vue';
import SeccionInformacionBancaria from './seccionInformacionBancaria.vue';
export default {
  components: {
    SeccionProveedor,
    SeccionTelefonos,
    SeccionConvenios,
    SeccionInformacionBancaria
  },
  data() {
    return {
      loading: false,
      titleVal: "",
      defaultTipoTransaccion: 56,
    };
  },
  methods: {
    ...mapActions([
      "getListProveedor",
      "_getDocumentsPais",
      "cargarMasterDetalleTipoProveedor",
      "cargarMasterDetalleTipoTransaccion",
      "_getRole",
      "getValidaRazonSocial",
      "cargarTipoTelefono",
      "getCoinsListCargar",
      "getBanksListCargar",
      "guardarCliente",
      "getValidaDocumentCliente",
      "_getSex",
      "_getPais",
      "_getState",
      "_getCity",
      "_getTown",
      "cargarTipoPersona",
      "_getProveedorRolShipper",
      "_getProveedor"
    ]),
    /* CÓDIGO NUEVO */
    getIDTipoTelefono(criterio = "") {
      var vm = this;
      var id = null;

      const tipoTelefonoData = vm.$store.state.masterusuarios.lstTipoTelefono.find(v => v.descripcion.toUpperCase() == criterio.toUpperCase());
      if (tipoTelefonoData) {
        id = tipoTelefonoData.id;
      }

      return id;
    },
    getIDTipoPersona(criterio = "") {
      var vm = this;
      var id = null;

      const tipoPersonaData = vm.$store.state.masterusuarios.lstTipoPersona.find(v => v.descripcion.toUpperCase() == criterio.toUpperCase());
      if (tipoPersonaData) {
        id = tipoPersonaData.id;
      }

      return id;
    },
    getIDTipoDocumento(criterio = "") {
      var vm = this;
      var id = null;

      const tipoDocumentoData = vm.$store.state.itemsDocumentsPais.find(v => v.name.toUpperCase() == criterio.toUpperCase());
      if (tipoDocumentoData) {
        id = tipoDocumentoData.id;
      }

      return id;
    },
  },
  created() {
    this.$store.state.entities.lstProveedores_x_nombre = [];
    this.$store.state.entities.lstProveedores_x_documento = [];
  },
  async mounted() {
    this.$store.state.spiner = true;
    //this.$refs.formProveedor.reset();
    await this._getDocumentsPais();
    await this.cargarMasterDetalleTipoProveedor();
    await this._getPais();
    await this.cargarMasterDetalleTipoTransaccion();
    await this.cargarTipoTelefono();
    await this._getRole();
    await this.getCoinsListCargar();
    await this.getBanksListCargar();
    await this._getSex();
    await this.cargarTipoPersona();
    await this._getProveedorRolShipper();
    this.$store.state.spiner = false;

    this.$store.state.entities.stepper = 1;
    this.$store.state.entities.isEdit = false;
    this.$store.state.entities.isReadonly = false;
    this.$store.state.entities.showBtnSaveFlag = false;

    this.$store.state.entities.proveedor.nombrecompleto = "";
    this.$store.state.entities.proveedor.id_tipoproveedor = "";
    this.$store.state.entities.proveedor.id_tipotransaccion = "56";
    this.$store.state.entities.proveedor.nro_documento = "";
    this.$store.state.entities.proveedor.id_pais = 0;
    this.$store.state.entities.proveedor.id_ciudad = 0;
    this.$store.state.entities.proveedor.direccion = "";
    this.$store.state.entities.proveedor.emailaddress = "";
    this.$store.state.entities.proveedor.producto = "";    
    this.$store.state.entities.proveedor.dias_sobreestadia = 0;
    this.$store.state.entities.proveedor.dias_almacenaje = 0;
    this.$store.state.entities.proveedor.notas = "";
    this.$store.state.entities.proveedor.convenioActual = { dias_credito: 0, fecha: "", email_soporte: "", id_path: "" };
    this.$store.state.entities.proveedor.telefonoActual = { id_tipotelefono: "", telefono: "" };    
    this.$store.state.entities.proveedor.contactoActual = { nombre: "", id_tipotelefono: "", telefono: "" };

    this.$store.state.entities.lstTelefonos = [];
    this.$store.state.entities.lstContactos = [];
    this.$store.state.entities.lstConvenios = [];
    this.$store.state.entities.lstTarifas = [];
    this.$store.state.entities.lstInformacionBancaria = [];
    this.$store.state.entities.lstShippers = [];
    this.$store.state.entities.informacionEntrega = { status: true };

    const tipoDocumento = this.getIDTipoDocumento("RUC");
    this.$store.state.entities.proveedor.id_tipodocumento = tipoDocumento;

    const tipoTelefono = this.getIDTipoTelefono("WHATSAPP");
    this.$store.state.entities.proveedor.telefonoActual.id_tipotelefono = tipoTelefono;
    this.$store.state.entities.proveedor.contactoActual.id_tipotelefono = tipoTelefono;
  },
  watch: {
    "$store.state.entities.stepper": {
      handler(newStep, oldStep) {
        if (this.$route.name == 'nuevoProveedor' && newStep === 4) {
          this.$store.state.entities.showBtnSaveFlag = true;
        }
        else {
          this.$store.state.entities.showBtnSaveFlag = false;
        }
      },
      immediate: true,
    },
  }
};
</script>

<style>
.v-stepper__content {
  padding: 24px;
}

.row+.row {
  margin-top: 0;
}

tr.v-data-table__selected {
  background: #ffccab !important;
}
</style>